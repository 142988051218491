import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Network from "utils/network"
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq, breakpoints } from 'utils/media-queries'
import { debounce } from "lodash";

import {
    Field,
    Form,
    WrapperInner,
    Label,
    Heading,
    Input,
    Subheading
} from 'utils/form-styles'
import ErrorMessage from 'components/error-message'
import SubmitButton from 'components/button-form';

export default class CreateAccount extends PureComponent {
    static propTypes = {

    }
    
    constructor(props) {
        super(props)

        this.state = getStateObject(props);
    }

    componentDidMount = () => {
        this.validateField = debounce(this.validateField, 500);
    }
       

    getPasswordRules() {
        return (
            <PasswordRules>
                <RuleHeading>Password must contain:</RuleHeading>
                <Rules>
                    <Rule>At least one number</Rule>
                    <Rule>At least one uppercase character</Rule>
                    <Rule>At least one of the special characters ! @ # &amp; _ . - +</Rule>
                </Rules>
            </PasswordRules>
        )
    }
    

    validateField(fieldName, value) {
        const field = {...this.state.fields[fieldName]};
        const validate = field.blurred || this.state.errorCount === 1;
        let isValid = false;
        
        if (validate) {
            switch(fieldName) {
                case 'email':
                    isValid = value.match(/^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/i);
                    field.error = isValid ? '' : 'Email address appears to be invalid.';
                    break;
                case 'confirmEmail':
                    isValid = value === this.state.fields.email.value; 
                    field.error = isValid ? '' : 'Confirmation email does not match email address.';
                    break;
                case 'password':
                    isValid = value.match(/((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!.#@_+,-]).{8,50})/);
                    field.error = isValid ? '' : this.getPasswordRules();
                    break;
                case 'confirmPassword':
                    isValid = value === this.state.fields.password.value; 
                    field.error = isValid ? '' : 'Confirmation password does not match password.';
                    break;
                default:
                    break;
            }
        }
      
        this.setState((state) => {
            return {
                fields: {
                    ...state.fields,
                    [fieldName]: field
                }
            }
        }, this.validateForm);
      }
      
    validateForm() {
        const {fields, errorCount} = this.state;
        let errorArray = [];

        for (const fieldName in fields) {
            let field;
            if (fields.hasOwnProperty(fieldName)) {
                field = fields[fieldName];
                if (field.required && !field.value === '') {
                    errorArray.push(field);
                }

                else if (errorCount > 1 && !field.blurred) {
                    errorArray.push(field);
                }
                
                else if (field.error) {
                    errorArray.push(field);
                }
            }
        }

        this.setState({
            errorCount: errorArray.length,
            formValid: errorArray.length === 0
        });
    }

    handleBlur = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const fieldUpdate = {...this.state.fields[name]};
        fieldUpdate.blurred = true;
        
        this.testField(name, value, fieldUpdate)
    }

    handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const fieldUpdate = {...this.state.fields[name]};
        fieldUpdate.value = value;

        this.testField(name, value, fieldUpdate)
        
    }

    testField = (name, value, fieldUpdate) => {


        this.setState((state) => {
            return {
                fields: {
                    ...state.fields,
                    [name]: fieldUpdate
                },
                submitted: false
            }
        }, () => { 
            this.validateField(name, value);
        });
    }

    handleSubmit = async (event) => {
        event.preventDefault()
        if(this.state.formValid) {
            
            this.setState({submitted: true});

            const {email, confirmEmail, password, confirmPassword} = this.state.fields;
            const data = {
                email: email.value,
                confirmEmail: confirmEmail.value,
                password: password.value,
                confirmPassword: confirmPassword.value,
                accountantName: this.state.accountantName
            }

            try {
                const response = await Network().post('api/public/create-account', data);
                if(response.verifyEmail) window.location = '/verification-sent';
                
                this.updateWithServerValidation(response);
            } catch (error) {
                error => console.warn(error);
            }
            
        }
    }

    updateWithServerValidation = (response) => {
        const fields = this.state.fields;

        let field, fieldName;

        if (response.emailConfirmFail) {
            fieldName = 'confirmEmail';
            field = fields.confirmEmail;
            field.error = 'Confirm Email field does not match email field.';
        }   
        
        else 

        if (response.emailInUse) {
            fieldName = 'email';
            field = fields.email;
            field.error = 'Email already in use.';
        }


        if (field && fieldName) {
            this.setState((state) => {
                return {
                    fields: {
                        ...state.fields,
                        [fieldName]: field
                    }
                }
            }, this.validateForm);
        }
        
    }


    render() {
        const {formValid, fields, submitted, accountantName} = this.state;
        const {email, confirmEmail, password, confirmPassword} = fields;

        return (
            <WrapperOuter>
                <WrapperInner>
                    <Heading>Create Account</Heading>
                    <Subheading>Email &amp; Password</Subheading>
                    <Form onSubmit={this.handleSubmit}>
                        <Field>
                            <Label htmlFor="email">Email</Label>
                            <Input 
                                name="email"
                                value={email.value} 
                                maxlength="50" 
                                onChange={this.handleInputChange}
                                onBlur={this.handleBlur}
                                />
                            <ErrorMessage>{email.error}</ErrorMessage>
                        </Field>
                        <Field>
                            <Label htmlFor="confirmEmail">Confirm Email</Label>
                            <Input 
                                name="confirmEmail"
                                value={confirmEmail.value} 
                                maxlength="50" 
                                onChange={this.handleInputChange}
                                onBlur={this.handleBlur}
                                />
                            <ErrorMessage>{confirmEmail.error}</ErrorMessage>
                        </Field>
                        <Field>
                            <Label htmlFor="password">Password</Label>
                            <Input 
                                type="password"
                                name="password"
                                value={password.value} 
                                maxlength="50" 
                                onChange={this.handleInputChange}
                                onBlur={this.handleBlur}
                                />
                            <ErrorMessage>{password.error}</ErrorMessage>
                        </Field>
                        <Field>
                            <Label htmlFor="confirmPassword">Confirm Password</Label>
                            <Input 
                                type="password"
                                name="confirmPassword"
                                value={confirmPassword.value} 
                                maxlength="50" 
                                onChange={this.handleInputChange}
                                onBlur={this.handleBlur}
                                />
                            <ErrorMessage>{confirmPassword.error}</ErrorMessage>
                        </Field>
                        <SubmitButton disabled={!formValid || submitted}>
                            Next
                        </SubmitButton>
                    </Form>
                </WrapperInner>
            </WrapperOuter>
        )
    }
}

const WrapperOuter = styled.div`
    font-size: 1rem;
    display: flex;
    justify-content: center;
    ${mq.medium(css`

    `)};

    ${mq.large(css`

    `)};

    @media only screen and (orientation: landscape) and (min-width: ${breakpoints.medium}px) {
        top: 0;
    }

    @media only screen and (orientation: landscape) and (min-width: ${breakpoints.xLarge}px) {
        top: 5%;
    }

`;

const TextArea = styled.textarea`

`;

const Select = styled.select`

`;


const PasswordRules = styled.div`
    
`;
const Rules = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
`;

const RuleHeading = styled.div`
    font-weight: bold;
`;

const Rule = styled.li`

`;

const getStateObject = (props) => {
    return {
        fields: {
            email: {
                required: true,
                blurred: false,
                error: '',
                value: ''
            },
            confirmEmail: {
                required: true,
                blurred: false,
                error: '',
                value: ''
            },
            password: {
                required: true,
                blurred: false,
                error: '',
                value: ''
            },
            confirmPassword: {
                required: true,
                blurred: false,
                error: '',
                value: ''
            }
        },
        
        formValid: false,
        submitted: false,
        errorCount: 4,
        accountantName: props.match.params.accountantName
    };
}